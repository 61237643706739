import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import GameItem from './GameItem'
import GameDrawer from './Drawer'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'


const GameGrid = ({ heading, products }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [openDrawer, setDrawerOpen] = useState(false)
  const stripePromise = loadStripe('YOUR_STRIPE_PUBLIC_KEY');
  return (
    <section className='game-grid-section py-5 px-2'>
      <h1 className="text-2xl font-extrabold mt-8 mb-8 text-gray-100 leading-9 uppercase tracking-tight">
        {heading}
      </h1>
      {isEmpty(products) ?
        <div className='max-w-1100 px-2 ml-auto mr-auto text-center'>
          <h1>Sorry! No products in database match your search query.</h1>
        </div>
        :
        <div className='game-grid grid gap-4'>
          {products?.map((product, idx) => product?.thumbnail && (
            <GameItem openDrawer={setDrawerOpen} setActiveItem={setActiveItem} data={product} key={idx} id={idx} />
          ))
          }
        </div>
      }
      <Elements stripe={stripePromise}>
      <GameDrawer openDrawer={openDrawer} setDrawerOpen={setDrawerOpen} activeItem={activeItem} />
      </Elements>
    </section>
  )
}

export default GameGrid;