import React from 'react'
import { Link } from 'react-router-dom'
// import useAuth from '../hooks/useAuth';

const Footer = () => {
  // const { auth } = useAuth();
  return (
    <footer className="text-white py-12 footer-container mt-8">
      <div className='footer-wrapper px-2'>
        <div className="container mx-auto flex flex-col md:flex-row md:space-evenly">
          <div className="mb-6 md:mb-0 md:w-1/4">
            <h3 className="text-2xl font-bold">GMarket.Africa</h3>
            <ul className="list-none text-gray-400 mt-4">
              <li className="mb-2 text-sm">
                General Payment Partner in the African region Tsinda Entertainment Limited
              </li>
              <li className="text-sm mt-2">
                Powered by Steam (registered trademark of Valve Corporation)
              </li>
              <li className="mb-2 text-sm mt-2">
                Email : <a href="mailto:info@stayonline.tech">info@gmarket.africa</a>
              </li>
            </ul>
            <ul className='list-none text-gray-400 mt-4'>
              <li>PVT-BEUX5PZ5</li>
              <li>P.O. BOX 53854 - 00200, NAIROBI.</li>
              <li>9 SPORTS ROAD,</li>
              <li>WESTLANDS, NAIROBI.</li>
            </ul>
          </div>
          <div className="mb-6 md:mb-0 md:w-1/4 sm:ml-10">
            <h3 className="text-2xl font-bold">Customer Service</h3>
            <ul className="list-none text-gray-400 mt-4">
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Contact Us</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">FAQ</Link></li>
              {/* <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Shipping & Returns</Link></li> */}
            </ul>
          </div>
          {/* <div className="mb-6 md:mb-0 md:w-1/4">
            <h3 className="text-2xl font-bold">Account</h3>
            <ul className="list-none text-gray-400 mt-4"> */}
          {/* {auth?.user
                ? <li className="mb-2">
                  <Link to='' className="text-base font-medium hover:text-gray-300">My Account</Link></li>
                : <li className="mb-2">
                  <Link to='/auth/login' className="text-base font-medium hover:text-gray-300">Sign In</Link></li>
              } */}
          {/* <li className="mb-2">
                  <Link to='' className="text-base font-medium hover:text-gray-300">Sign In</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Order History</Link></li>
            </ul>
          </div> */}
          {/* <div className="md:w-1/4">
            <h3 className="text-2xl font-bold">Contact</h3>
            <ul className="list-none text-gray-400 mt-4">
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Email</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Phone</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Live Chat</Link></li>
            </ul>
          </div> */}
        </div>
        <div className="mt-8 border-t flex-col border-gray-700 pt-8 md:flex ">
          <div className="text-sm font-bold py-2 uppercase">
            Accepted Payments:
          </div>
          <div className="flex mt-4 md:mt-2">
            <img src='/assets/images/footer/payment.png' alt='American Express, VISA, VISA Electron, VISA Debit, VISA Carte Bleue, VISA Electron Carte Bleue, VISA Debit Carte Bleue, VISA Purchasing, Maestro, Mastercard, Debit Mastercard, PayPal,' />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer