export const db = [
  {
    title: 'Spider-Man Remastered',
    thumbnail: 'spiderman.png',
    category: 'keys',
    releaseDate: '11/12/2020',
    description: "This isn't the Spider-Man you've met or ever seen before. In Marvel's Spider-Man Remastered, we meet an experienced Peter Parker who's more masterful at fighting big crime in New York City. At the same time, he's struggling to balance his chaotic personal life and career while the fate of Marvel's New York rests upon his shoulders.",
    price: '€46'
  },
  {
    title: 'GTA V: Premium Online Edition',
    thumbnail: 'gtav.png',
    category: 'keys',
    releaseDate: 'November 18, 2014',
    description: 'When a young street hustler, a retired bank robber and a terrifying psychopath find themselves entangled with some of the most frightening and deranged elements of the criminal underworld, the U.S. government and the entertainment industry, they must pull off a series of dangerous heists to survive in a ruthless city in which they can trust nobody, least of all each other.',
    price: '€11'
  },
  {
    title: 'God of War',
    thumbnail: 'gow.png',
    category: 'keys',
    releaseDate: 'November 9, 2022',
    description: 'From Santa Monica Studio comes the sequel to the critically acclaimed God of War (2018). Embark on an epic and heartfelt journey as Kratos and Atreus struggle with holding on and letting go in God of War Ragnarök.',
    price: '€44'
  },
  {
    title: 'Farming Simulator 22 Platinum Edition',
    thumbnail: 'farmingsim.png',
    category: 'keys',
    releaseDate: 'November 22, 2021',
    description: 'Enjoy a huge variety of farming operations focusing on agriculture, animal husbandry and forestry. Operate real machines from John Deere, CLAAS, Case IH, DEUTZ-FAHR, New Holland, Fendt, Massey Ferguson, Valtra and many others in three diverse American and European environments. ',
    price: '€34'
  },
  {
    title: 'Assetto Corsa',
    thumbnail: 'assettocorsa.png',
    category: 'keys',
    releaseDate: '8/30/2016',
    description: 'This is not just a game.   This is real sim racing.   In collaboration with the most prestigious car manufacturers, Assetto Corsa ups the simulation stakes with a focus on pure driving realism, with pin-point accuracy across every aspect of the game.',
    price: '€34'
  },
  {
    title: 'The Elder Scrolls V: Skyrim Special Edition',
    thumbnail: 'skyrim.png',
    category: 'keys',
    releaseDate: '10/28/2016',
    description: 'Skyrim reimagines and revolutionizes the open-world fantasy epic, bringing to life a complete virtual world open for you to explore any way you choose.',
    price: '€18'
  },
  {
    title: 'WRC Generations',
    thumbnail: 'wrc.png',
    category: 'keys',
    releaseDate: '11/3/2022',
    description: '2022 will see the WRC transition to the hybrid era. This is a revolution for the rally world, significantly affecting performance, changing strategies and making drivers and teams adapt.',
    price: '€18'
  },
  {
    title: 'Call of Duty: Modern Warfare 2',
    thumbnail: 'codmw2.png',
    category: 'keys',
    releaseDate: 'October 28, 2022',
    description: 'Call of Duty®: Modern Warfare® II drops players into an unprecedented global conflict that features the return of the iconic Operators of Task Force 141.',
    price: '€19'
  },
  {
    title: 'Roblox: $25 Topup',
    thumbnail: 'roblox.png',
    category: 'topup',
    price: '€29'
  },
  {
    title: 'No Man\'s Sky',
    thumbnail: 'nomansky.png',
    category: 'keys',
    releaseDate: '7/24/2018',
    description: 'Inspired by the adventure and imagination that we love from classic science-fiction, No Man\'s Sky presents you with a galaxy to explore, filled with unique planets and lifeforms, and constant danger and action.',
    price: '€23'
  },
  {
    title: 'Gotham Knights',
    thumbnail: 'gothamknights.png',
    category: 'keys',
    releaseDate: '10/21/2022',
    description: 'Batman is dead. A new expansive, criminal underworld has swept the streets of Gotham City. It is now up to the Batman Family - Batgirl, Nightwing, Red Hood, and Robin - to protect Gotham, bring hope to its citizens, discipline to its cops, and fear to its criminals.',
    price: '€56'
  },
  {
    title: 'Horizon Zero Dawn | Complete Edition',
    thumbnail: 'horizonzero.png',
    category: 'keys',
    releaseDate: '28 February 2017',
    description: 'Experience Aloy’s legendary quest to unravel the mysteries of a future Earth ruled by Machines. Use devastating tactical attacks against your prey and explore a majestic open world in this award-winning action RPG!',
    price: '€18'
  },
  {
    title: 'MORDHAU',
    thumbnail: 'mordhau.png',
    category: 'keys',
    releaseDate: '29 April, 2019',
    description: 'MORDHAU is a multiplayer medieval slasher. Create your mercenary and fight in brutal battles where you will experience fast paced combat, castle sieges, cavalry charges, and more.',
    price: '€8'
  },
  {
    title: 'Resident Evil 8: Village',
    thumbnail: 'residentevil8.png',
    category: 'keys',
    releaseDate: '5/7/2021',
    description: 'Set a few years after the horrifying events in the critically acclaimed Resident Evil 7 biohazard, the all-new storyline begins with Ethan Winters and his wife Mia living peacefully in a new location, free from their past nightmares. Just as they are building their new life together, tragedy befalls them once again.',
    price: '€25'
  },
  {
    title: 'PAYDAY 2',
    thumbnail: 'payday2.png',
    category: 'keys',
    releaseDate: '6/16/2015',
    description: 'PAYDAY is an action-packed, four-player co-op shooter where you rob banks and get paid.The Crimewave edition includes an enhanced graphic engine. Up to four friends co-operate on the hits and as the crew progresses the jobs become bigger, better and more rewarding.',
    price: '€10'
  },
  {
    title: 'Dead by Daylight',
    thumbnail: 'deadbydaylight.png',
    category: 'keys',
    releaseDate: '6/20/2017',
    description: 'Choose between playing an unstoppable Killer and one of 4 Survivors trying to evade a gruesome death. Each character has their own deep progression system and plenty of unlockables that can be customized to fit your personal strategy. Work together to escape, or stalk and sacrifice every Survivor.',
    price: '€10'
  },
  {
    title: 'DOOM Eternal',
    thumbnail: 'doometernal.png',
    category: 'keys',
    releaseDate: '3/20/2020',
    description: 'Hell’s armies have invaded Earth. Become the Slayer in an epic single-player campaign to conquer demons across dimensions and stop the final destruction of humanity.',
    price: '€21'
  },
  {
    title: 'Outlast 2',
    thumbnail: 'outlast2.png',
    category: 'keys',
    releaseDate: '4/25/2017',
    description: 'Outlast 2 introduces you to Sullivan Knoth and his followers,  who left our wicked world behind to give birth to Temple Gate, a town deep in the wilderness and hidden from civilization. Knoth and his flock are preparing for the tribulations of the end of times and you’re right in the thick of it.',
    price: '€4'
  },
  {
    title: 'Euro Truck Simulator 2 Gold Edition',
    thumbnail: 'eurotracksim2.png',
    category: 'keys',
    releaseDate: '18 October 2012',
    description: ' Travel across Europe as king of the road, a trucker who delivers important cargo across impressive distances! With dozens of cities to explore, your endurance, skill and speed will all be pushed to their limits.',
    price: '€8'
  },
  {
    title: 'Arma 3',
    thumbnail: 'arma3.png',
    category: 'keys',
    releaseDate: '12 September, 2013',
    description: 'Experience true combat gameplay in a massive military sandbox. Deploying a wide variety of single- and multiplayer content, over 20 vehicles and 40 weapons, and limitless opportunities for content creation, this is the PC’s premier military game. Authentic, diverse, open - Arma 3 sends you to war.',
    price: '€17'
  },
  {
    title: 'Roblox: $10 Topup',
    thumbnail: 'roblox.png',
    category: 'topup',
    price: '€11'
  },
  {
    title: 'Fallout: New Vegas Ultimate Edition',
    thumbnail: 'falloutnewvegas.png',
    category: 'keys',
    releaseDate: '6/13/2021',
    description: 'With the introduction of the Ultimate Edition, Bethesda Softworks offers you the chance to double-down and get the complete package of New Vegas fun. For the first time in one package, you can get all the Fallout: New Vegas content including the full suite of highly acclaimed add-on content: Dead Money, Honest Hearts, Old World Blues and Lonesome Road.',
    price: '€9'
  },
  {
    title: 'Squad',
    thumbnail: 'squad.png',
    category: 'keys',
    releaseDate: '23 September, 2020',
    description: ' Squad is a tactical FPS that provides authentic combat experiences through teamwork, constant communication, and realistic gameplay. It bridges the gap between arcade shooter and military simulation with 100 player battles, combined arms combat, base building, and an integrated VoIP system.',
    price: '€9'
  },
  {
    title: 'Batman: Arkham Collection',
    thumbnail: 'batmanarkham.png',
    category: 'keys',
    releaseDate: '    11/27/2018',
    description: 'Experience two of the most critically acclaimed titles of the last generation - Batman: Arkham Asylum and Batman: Arkham City, with fully remastered and updated visuals. Complete your experience with the explosive finale to the Arkham series in Batman: Arkham Knight.',
    price: '€7'
  },
  {
    title: 'Sid Meier\'s Civilization VI | Platinum Edition',
    thumbnail: 'civilizationvi.png',
    category: 'keys',
    releaseDate: '21 October, 2016',
    description: 'Civilization VI is the newest installment in the award winning Civilization Franchise. Expand your empire, advance your culture and go head-to-head against history’s greatest leaders. Will your civilization stand the test of time?',
    price: '€22'
  },
  {
    title: 'Hearts of Iron IV: Cadet Edition',
    thumbnail: 'heartsofiron.png',
    category: 'keys',
    releaseDate: '11/13/2019',
    description: 'Victory is at your fingertips! Your ability to lead your nation is your supreme weapon, the strategy game Hearts of Iron IV lets you take command of any nation in World War II; the most engaging conflict in world history.',
    price: '€11'
  },
  {
    title: 'The Jackbox Party Pack 9',
    thumbnail: 'jackbox.png',
    category: 'keys',
    releaseDate: '10/20/2022',
    description: 'Introducing the ninth installment of the best-selling Party Pack Franchise you know and love! Whether you’re hanging out with friends, having a remote happy hour, trying to make the holidays less awkward, or looking for your next game to stream, The Jackbox Party Pack 9 is here to spice things up.',
    price: '€23'
  },
  {
    title: 'Borderlands 3 Super Deluxe Edition',
    thumbnail: 'borderlands3.png',
    category: 'keys',
    releaseDate: '13 March, 2020',
    description: 'The original shooter-looter returns, packing bazillions of guns and a mayhem-fueled adventure! Blast through new worlds and enemies as one of four new Vault Hunters.',
    price: '€38'
  },
  {
    title: 'Mount & Blade II: Bannerlord',
    thumbnail: 'mountbladeii.png',
    category: 'keys',
    releaseDate: '25 October, 2022',
    description: 'A strategy/action RPG. Create a character, engage in diplomacy, craft, trade and conquer new lands in a vast medieval sandbox. Raise armies to lead into battle and command and fight alongside your troops in massive real-time battles using a deep but intuitive skill-based combat system.',
    price: '€32'
  },
]