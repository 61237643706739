import React, { useState } from 'react'
import { motion } from 'framer-motion'
const Hero = ({ searchItem }) => {
  const [searchInput, setSearchInput] = useState('');
  const handleInputChange = (e) => {
    return setSearchInput(e.target.value)
  }
  const handleSearch = (e) => {
    e.preventDefault()
    return searchItem(searchInput)
  }
  return (
    <div className="hero-component bg-gradient-to-r from-indigo-700 to-purple-700 text-white p-10 flex items-center justify-center relative">
      <div className="text-center">
        <h1 className="text-5xl font-bold">GMARKET</h1>
        <p className="text-lg mt-2 opacity-80">Africa's digital marketplace for topups, keys, tutorials, accessories and many more.</p>
        <form onSubmit={handleSearch}>
          <div className="mt-5 flex items-center justify-center">
            <input
              value={searchInput}
              type="text"
              onChange={handleInputChange}
              className="text-black p-2 bg-white border"
              placeholder="Search GMarketplace..." />
            <button
              type='submit'
              className="ml-2 p-2 border box-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            </button>
          </div>
        </form>
      </div>
      <div className='sections-grid-wrapper absolute overflow-x-scroll'>
        <div className='grid  sections-grid gap-2 px-2'>
          <a href='https://skin.club/en' target='_blank' rel='noreferrer noopener'>
            <motion.div
              whileHover={{ y: 10 }}
              className='section-card cursor-pointer bg-white text-purple-700 text-center shadow rounded-md flex items-center justify-center flex-col p-5 font-heading'>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="13.5" cy="6.5" r=".5"></circle><circle cx="17.5" cy="10.5" r=".5"></circle><circle cx="8.5" cy="7.5" r=".5"></circle><circle cx="6.5" cy="12.5" r=".5"></circle><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c.926 0 1.648-.746 1.648-1.688 0-.437-.18-.835-.437-1.125-.29-.289-.438-.652-.438-1.125a1.64 1.64 0 0 1 1.668-1.668h1.996c3.051 0 5.555-2.503 5.555-5.554C21.965 6.012 17.461 2 12 2z"></path></svg>
              <h6 className='text-xl cursor-pointer mt-3'>Skins</h6>
            </motion.div>
          </a>
          <a href='https://theplatinum.co/' target='_blank' rel='noreferrer noopener'>
            <motion.div
              whileHover={{ y: 10 }}
              className='section-card cursor-pointer bg-white text-purple-700 text-center shadow rounded-md flex items-center justify-center flex-col p-5 font-heading'>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path><path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path><path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path><path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path></svg>
              <h6 className='text-xl cursor-pointer mt-3'>Topups</h6>
            </motion.div>
          </a>
          <a href='https://futcoin.net/' target='_blank' rel='noopener noreferrer'>
            <motion.div
              whileHover={{ y: 10 }}
              className='section-card cursor-pointer bg-white text-purple-700 text-center shadow rounded-md flex items-center justify-center flex-col p-5 font-heading'>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
              <h6 className='text-xl cursor-pointer mt-3'>Keys</h6>
            </motion.div>
          </a>
          <a href='http://courses-by-masters.pro/' target='_blank' rel='noopener noreferrer'>
            <motion.div
              whileHover={{ y: 10 }}
              className='section-card cursor-pointer bg-white text-purple-700 text-center shadow rounded-md flex items-center justify-center flex-col p-5 font-heading'>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-graduation-cap"><path d="M22 10v6M2 10l10-5 10 5-10 5z" /><path d="M6 12v5c3 3 9 3 12 0v-5" /></svg>
              <h6 className='text-xl cursor-pointer mt-3'>Tutorials</h6>
            </motion.div>
          </a>
        </div>
      </div>
    </div>
  );
};


export default Hero