import React, { useState, useEffect } from 'react'
import GameGrid from '../components/GameGrid'
import Hero from '../components/Hero'
import HomeLayout from '../layouts/home'
import { db as database } from '../_data/games'

const Home = () => {
  const [products, setProducts] = useState(database);
  useEffect(()=>{
    setProducts(database)
  },[])
  const searchItem = (query) => {
    if (query.trim() === '') {
      return setProducts(database)
    }
    const filtered = products.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    setProducts(filtered);
    
    return setProducts(filtered)
  }
  return (
    <HomeLayout>
      <Hero searchItem={searchItem} />
      <GameGrid products={products} />
    </HomeLayout>
  )
}

export default Home