import React from 'react'
import Footer from '../components/Footer'
import {ToastContainer} from 'react-toastify'

const HomeLayout = ({ children }) => {
  return (
    <main>
      {children}
      <Footer />
      <ToastContainer limit={1} />
    </main>
  )
}

export default HomeLayout